import React from "react"
import Img from "gatsby-image"

const PortfolioCard = ({ data }) => (
  <a 
    href={data.frontmatter.slug} 
    className={data.frontmatter.ordem % 2 === 0 ? `box-menor box-right box-hover menor-bg` : `box-maior box-left box-hover maior-bg`}
  >
    <div className="hover-box">
      <div className="text-box">
        <h3>{data.frontmatter.title}</h3>
        <hr />
        <div>{data.frontmatter.description}</div>
      </div>
    </div>
    <Img fluid={data.frontmatter.featuredImage.childImageSharp.fluid} />
  </a>
)

export default PortfolioCard